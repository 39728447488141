import { I18nContext } from "components/I18N";
import Link from "next/link";
import Cookies from "universal-cookie";
import { useContext, useEffect, useState } from "react";

const CookieDialog = () => {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);

  const cookies = new Cookies();
  const [showCookieDialog, setShowCookieDialog] = useState(false);

  const onClickAccept = () => {
    cookies.set("isAcceptCookie", "true");
    setShowCookieDialog(false);
  };

  useEffect(() => {
    const _accepted = cookies.get("isAcceptCookie");
    if (_accepted !== "true") {
      setShowCookieDialog(true);
    }
  }, []);

  if (!showCookieDialog) {
    return <div />;
  }

  return (
    <div
      className={`fixed bottom-0 left-0 w-screen py-[28px] bg-[#3B3F2F] text-white row-mobile-col justify-center items-center z-[99999] gap-4 lg:gap-6 ${langCode}-font`}
    >
      <p className="small w-full md:w-[unset] px-[16px] text-center md:text-left">
        {I18n.get("cookieConsentMessage")}
        <Link href="/privacy-policy">
          <a className="text-[#61E3D4] underline">
            {I18n.get("cookieConsentMessageLink")}
          </a>
        </Link>
      </p>

      <div className="flex gap-4 lg:gap-6">
        <button
          className="px-[16px] h-[40px] rounded-[8px] bg-white row-center text-[#060606]"
          onClick={onClickAccept}
        >
          {I18n.get("accept")}
        </button>

        <Link href="/cookie-settings">
          <button className="px-[16px] h-[40px] rounded-[8px] bg-[#3B3F2F] row-center text-white border border-white">
            {I18n.get("setting")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CookieDialog;
