import { Minimize } from "@mui/icons-material";
import { I18nContext } from "components/I18N";
import Link from "next/link";
import { useContext } from "react";
import { useState, useEffect } from "react";

function CategoryList({ fWidth, carousel }) {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  const [active, setActive] = useState(0);

  try {
    // check if LiveChatWidget available or not
    // if available and if hidden then make it Minimize(visible)
    if (LiveChatWidget.get("state").visibility === "hidden") {
      console.log("yes hidden");
      LiveChatWidget.call("minimize");
    }
  } catch (e) {}

  useEffect(() => {
    if (active === 0) {
      document.getElementById("cat-scroll").scrollLeft = 0;
    }
    if (active === 1) {
      document.getElementById("cat-scroll").scrollLeft =
        document.getElementById("cat-item-1").offsetLeft - 17;
    }
    if (active === 2) {
      document.getElementById("cat-scroll").scrollLeft =
        document.getElementById("cat-item-2").offsetLeft - 17;
    }
  }, [active]);

  useEffect(() => {
    setActive(0);
  }, []);

  return (
    <div className={`${langCode}-font h-[280px] lg:h-[280px]  left-0`}>
      <div
        id="cat-scroll"
        className={
          `mx-auto px-2 h-[214px] lg:h-[268px] flex space-x-[32px] overflow-x-auto ` +
          (fWidth ? `w-[${fWidth}]` : "w-[100vw] lg:w-max")
        }
      >
        <Link href="/erectile-dysfunction">
          <div
            id="cat-item-0"
            className="bg-[#F7F5F1] cursor-pointer flex-none flex-col pt-[24px] row-between w-[280px] h-[214px] lg:w-[350px] lg:h-[268px] rounded-[12px]"
          >
            <p className="title font-bold">{I18n.get("erectileDysfunction")}</p>

            <div className="row-center space-x-[10px] cursor-pointer">
              <p className="subtitle whitespace-nowrap">
                {I18n.get("conRead")}
              </p>
              <img
                alt="home list"
                src="/images/include/right-black.png"
                className="h-[10px] w-auto object-contain"
              />
            </div>
            <div className="flex-auto flex items-end">
              <img
                alt="home list"
                src="/images/products/ed.png"
                className="h-[130px] lg:h-[184px] w-auto object-cover object-left"
              />
            </div>
          </div>
        </Link>
        <Link href="/premature-ejaculation">
          <div
            id="cat-item-1"
            className="bg-[#2D3345] text-white cursor-pointer flex-none flex-col pt-[24px] row-between w-[280px] h-[214px] lg:w-[350px] lg:h-[268px] rounded-[12px]"
          >
            <p className="title font-bold">
              {I18n.get("prematureEjaculation")}
            </p>
            <div className="row-center space-x-[10px] cursor-pointer">
              <p className="subtitle whitespace-nowrap">
                {I18n.get("conRead")}
              </p>
              <img
                alt="home list"
                src="/images/include/right.png"
                className="h-[10px] w-auto object-contain"
              />
            </div>
            <div className="flex-auto flex items-end">
              <img
                alt="home list"
                src="/images/products/pe.png"
                className="h-[130px] lg:h-[184px] w-auto object-cover object-left"
              />
            </div>
          </div>
        </Link>
        <Link href="/hair-loss">
          <div
            id="cat-item-2"
            className="bg-[#53866B] text-white cursor-pointer flex-none flex-col pt-[24px] row-between w-[280px] h-[214px] lg:w-[350px] lg:h-[268px] rounded-[12px]"
          >
            <p className="text-center title font-bold">
              {I18n.get("homePageHairItem")}
            </p>
            <div className="row-center space-x-[10px] cursor-pointer">
              <p className="subtitle whitespace-nowrap">
                {I18n.get("conRead")}
              </p>
              <img
                alt="home list"
                src="/images/include/right.png"
                className="h-[10px] w-auto object-contain"
              />
            </div>
            <div className="flex-auto flex items-end">
              <img
                alt="home list"
                src="/images/products/hl.png"
                className="h-[130px] lg:h-[160px] w-auto object-cover object-left"
              />
            </div>
          </div>
        </Link>
      </div>
      {!carousel && (
        <div className="lg:hidden mt-2 flex space-x-[16px] pl-[17px] lg:pl-0 lg:space-x-[32px] lg:ml-auto lg:pr-[100px]">
          <div
            onClick={() => {
              if (active > 0) {
                setActive(active - 1);
              }
            }}
            className="flex-none circle bg-[#fff]"
          >
            <img
              alt="home list"
              src="/images/include/left.png"
              className="w-auto h-[12px] object-contain"
            />
          </div>
          <div
            onClick={() => {
              if (active < 2) {
                setActive(active + 1);
              }
            }}
            className="flex-none circle bg-[#060606]"
          >
            <img
              alt="home list"
              src="/images/include/right.png"
              className="w-auto h-[12px] object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CategoryList;
