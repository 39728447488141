// @FIXME how it work link?
// @FIXME payment confirm link?

import { useState, useEffect, useContext } from "react";
import Script from "next/script";
import Link from "next/link";
import { I18nContext } from "components/I18N";

export default function Footer() {
  const [showing, setShowing] = useState(false);
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);

  useEffect(() => {
    setShowing(true);
  }, []);

  if (!showing) {
    return null;
  }
  if (typeof window === "undefined") {
    return <></>;
  }

  return (
    <div
      className={`relative ${langCode}-font container-wrapper row-mobile-col py-[64px] space-y-[24px] lg:space-y-0 lg:space-x-[60px] xl:space-x-[72px] w-full h-[994px] lg:h-[470px] bg-[#3B3F2F] text-white`}
    >
      <div className="flex-none w-[80%] lg:w-[20%]">
        <img
          alt="hi bro logo"
          src="/images/include/logo-white.png"
          className="w-[100px] lg:w-[120px] h-auto object-contain mb-[25px] lg:mb-[50px]"
        />
        <div className="flex flex-col space-y-[18px]">
          <div className="row space-x-[12px] cursor-pointer">
            <img
              alt="tel"
              src="/images/include/footer/tel.png"
              className="h-[15px] w-auto object-contain"
            />
            <p className="subtitle1">(02) 200 1032</p>
          </div>
          <div className="row space-x-[12px] cursor-pointer">
            <img
              alt="email"
              src="/images/include/footer/email.png"
              className="h-[15px] w-auto object-contain"
            />
            <p className="subtitle1">care@sayhibro.com</p>
          </div>
          <div className="row space-x-[12px]">
            <a href="https://www.instagram.com/say.hibro/">
              <img
                alt="instagram"
                src="/images/include/footer/instagram.png"
                className="cursor-pointer h-[15px] w-auto object-contain"
              />
            </a>
            <a href="https://www.facebook.com/hibrohealth24">
              <img
                alt="facebook"
                src="/images/include/footer/facebook.png"
                className="cursor-pointer h-[15px] w-auto object-contain"
              />
            </a>
            <a href="https://page.line.me/hibro">
              <img
                alt="line"
                src="/images/include/footer/line.png"
                className="cursor-pointer h-[15px] w-auto object-contain"
              />
            </a>
            <a href="https://www.youtube.com/channel/UC6rFSpcNXO2P-o5OCf5RB5Q">
              <img
                alt="youtube"
                src="/images/include/footer/youtube.png"
                className="cursor-pointer h-[15px] w-auto object-contain"
              />
            </a>
          </div>
          <p
            className="subtitle1 lg:pt-[50px]"
            dangerouslySetInnerHTML={{ __html: I18n.get("ifInterested") }}
          />
        </div>
      </div>
      <div className="lg:flex-1 row-mobile-col space-y-[24px] lg:space-y-0 ">
        <div className="lg:flex-1 flex flex-row lg:flex-col">
          <div className="flex-1 flex flex-col space-y-[8px]">
            <p className="subtitle1 font-extrabold font-[CentraNo2]">
              TREATMENT
            </p>
            <Link href="/erectile-dysfunction">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("erectileDysfunction")}
              </p>
            </Link>
            <Link href="/premature-ejaculation">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("prematureEjaculation")}
              </p>
            </Link>
            <Link href="/hair-loss">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("homePageHairItem")}
              </p>
            </Link>
          </div>
          <div className="flex-1 flex flex-col space-y-[8px]">
            <p className="subtitle1 font-extrabold font-[CentraNo2]">LEARN</p>
            <Link href="/erectile-dysfunction-101">
              <p className="subtitle1 cursor-pointer">
                Erectile Dysfunction 101
              </p>
            </Link>
            <Link href="/premature-ejaculation-101">
              <p className="subtitle1 cursor-pointer">
                Premature Ejaculation 101
              </p>
            </Link>
            <Link href="/hair-loss-101">
              <p className="subtitle1 cursor-pointer">Hair Loss 101</p>
            </Link>
          </div>
        </div>
        {/* <div className="row-between-start lg:flex-1 lg:space-x-[30px] xl:space-x-[100px]"> */}
        <div className="row-between-start lg:flex-1">
          <div className="flex-1 flex flex-col space-y-[8px]">
            <p className="subtitle1 font-extrabold font-[CentraNo2]">FAQ</p>
            {/* <p className="subtitle1 cursor-pointer">
              {I18n.get('footerHowItWork')}
            </p> */}
            <Link href="/our-story">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("footerAbout")}
              </p>
            </Link>
            <Link href="/medical-team">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("footerMDTeam")}
              </p>
            </Link>
            <Link href="/blog">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("footerBlog")}
              </p>
            </Link>
            {/* <p className="subtitle1 cursor-pointer">
              {I18n.get('footerPayConfirm')}
            </p> */}
          </div>
          <div className="flex-1 flex flex-col space-y-[8px]">
            <p className="subtitle1 font-extrabold font-[CentraNo2]">SUPPORT</p>
            <Link href="/help-center">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("footerHelpCenter")}
              </p>
            </Link>
            <Link href="/contact">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("footerContactUs")}
              </p>
            </Link>
            <Link href="/privacy-policy">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("footerPrivacy")}
              </p>
            </Link>
            <Link href="/terms">
              <p className="subtitle1 cursor-pointer">
                {I18n.get("footerTermOfUse")}
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex-none w-[80%] lg:w-[20%]">
        <div className="flex-1 flex flex-col space-y-[8px]">
          <p className="subtitle1 font-extrabold font-[CentraNo2]">
            PAYMENT METHODS
          </p>
          <div className="row space-x-[8px]">
            <img
              alt="mastercard"
              src="/images/include/footer/mastercard.png"
              className="h-[36px] w-auto object-contain"
            />
            <img
              alt="visa"
              src="/images/include/footer/visa.png"
              className="h-[36px] w-auto object-contain"
            />
            <img
              alt="promptpay"
              src="/images/include/footer/promptpay.png"
              className="h-[36px] w-auto object-contain"
            />
          </div>
          <p className="subtitle1 pt-[24px]">{I18n.get("certifyBy")}</p>
          <Script
            id="dbd-init"
            src="https://www.trustmarkthai.com/callbackData/initialize.js?t=6bda664-16-5-7ba69cf1871904bb664d0b5ddabcd222b5e902"
          />
          <div id="Certificate-banners"></div>
        </div>
      </div>
      <div
        onClick={() => {
          document.body.scrollIntoView();
        }}
        className="cursor-pointer absolute bottom-[32px] left-[10px] lg:left-auto lg:right-[32px] w-[156px] h-[40px] lg:w-[200px] lg:h-[52px] row-center space-x-[12px] rounded-[8px] border border-white text-white"
      >
        <p className="title font-semibold">{I18n.get("backToTop")}</p>
        <img
          alt="top"
          src="/images/include/up-white.png"
          className="w-[10px] h-auto object-contain"
        />
      </div>
    </div>
  );
}
