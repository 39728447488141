import { connect } from "react-redux";
import { LanguageSelect } from "../I18N";
import { useRouter } from "next/router";

import { logout } from "../../src/redux/action/auth";
import get from "lodash/get";
import Link from "next/link";
import { useEffect, useState, useContext } from "react";
import { I18nContext } from "components/I18N";
import CategoryList from "../home/CategoryList";

function Header({ auth, logout, menuOffset }) {
  const router = useRouter();
  const [showTreatmentMenu, setShowTreatmentMenu] = useState(false);
  useState(false);
  const [hideTimer, setHideTimer] = useState(null);
  const { I18n, langCode } = useContext(I18nContext);
  return (
    <div className="w-full">
      <div className="flex w-full">
        <Link href="/">
          <img
            alt="logo"
            src="/images/include/logo.png"
            className="w-[72px] h-[24px] lg:w-[120px] lg:h-[40px] cursor-pointer"
          />
        </Link>
        <div className="ml-auto flex mt-[7px] lg:mt-[10px] justify-between space-x-[10px] md:space-x-[26px] items-center text-[#2A2A2A] text-[12px] sm:text-[14px]">
          <div
            onClick={() => {
              // router.push("/treatment");
            }}
            className="cursor-pointer flex items-center space-x-[3px] sm:space-x-[10px]"
            onMouseEnter={() => {
              if (hideTimer) {
                clearTimeout(hideTimer);
                setHideTimer(null);
              }
              if (!showTreatmentMenu) {
                setShowTreatmentMenu(true);
              }
            }}
            onMouseLeave={() => {
              setHideTimer(
                setTimeout(() => {
                  setShowTreatmentMenu(false);
                }, 800)
              );
            }}
          >
            <p>TREATMENT</p>
            <img
              alt="down"
              src="/images/include/down.png"
              className="w-[7px] h-[4px] object-contain"
            />
          </div>
          <p
            className="cursor-pointer"
            onClick={() => {
              if (get(auth, "user", false)) {
                router.push("/profile");
              } else {
                router.push("/login");
              }
            }}
          >
            {get(auth, "user", false) ? "PROFILE" : "LOGIN"}
          </p>
          <LanguageSelect />
        </div>
      </div>
      <div className="flex">
        <div
          className={`z-10 duration-500 absolute w-max h-max p-2 lg:p-8 right-2 lg:right-4 bg-white ${
            menuOffset ? "top-[100px]" : "top-[60px]"
          } ${
            showTreatmentMenu ? "opacity-100 shadow-xl" : "-z-10 opacity-0"
          } ${
            showTreatmentMenu && (menuOffset ? "top-[120px]" : "top-[80px]")
          }`}
          onMouseOver={() => {
            if (hideTimer) {
              clearTimeout(hideTimer);
              setHideTimer(null);
            }
          }}
          onMouseLeave={() => {
            if (showTreatmentMenu) {
              setHideTimer(
                setTimeout(() => {
                  setShowTreatmentMenu(false);
                }, 300)
              );
            }
          }}
        >
          <div className="w-full flex">
            <img
              className="w-4 mb-2 mr-1 ml-auto cursor-pointer"
              src="/images/close2.png"
              onClick={() => {
                setShowTreatmentMenu(false);
              }}
            ></img>
          </div>
          <div className="block lg:flex">
            <div className="flex-1 flex flex-col space-y-[8px] mr-8 w-[205px] mb-6 lg:mb-0">
              <p className="subtitle1 font-extrabold font-[CentraNo2]">
                TREATMENT
              </p>
              <Link href="/erectile-dysfunction">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  {I18n.get("erectileDysfunction")}
                </p>
              </Link>
              <Link href="/premature-ejaculation">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  {I18n.get("prematureEjaculation")}
                </p>
              </Link>
              <Link href="/hair-loss">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  {I18n.get("homePageHairItem")}
                </p>
              </Link>
            </div>

            <div className="flex-1 flex flex-col space-y-[8px] mr-8 w-[205px] mb-6 lg:mb-0">
              <p className="subtitle1 font-extrabold font-[CentraNo2]">LEARN</p>
              <Link href="/erectile-dysfunction-101">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  Erectile Dysfunction 101
                </p>
              </Link>
              <Link href="/premature-ejaculation-101">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  Premature Ejaculation 101
                </p>
              </Link>
              <Link href="/hair-loss-101">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  Hair Loss 101
                </p>
              </Link>
            </div>

            <div className="w-20 flex-1 flex flex-col space-y-[8px] mr-1 w-[205px] mb-6 lg:mb-0">
              <p className="subtitle1 font-extrabold font-[CentraNo2]">
                SUPPORT
              </p>
              <Link href="/our-story">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  {I18n.get("footerAbout")}
                </p>
              </Link>
              <Link href="/medical-team">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  {I18n.get("footerMDTeam")}
                </p>
              </Link>
              <Link href="/blog">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  {I18n.get("footerBlog")}
                </p>
              </Link>
              <Link href="/help-center">
                <p className="subtitle1 cursor-pointer font-[NotoSans]">
                  {I18n.get("footerHelpCenter")}
                </p>
              </Link>
            </div>
            <Link href="/hair-loss">
              <div
                id="cat-item-2"
                className="bg-[#53866B] text-white cursor-pointer flex py-[10px] px-[13px] row-between w-[278px] h-[134px] lg:w-[278px] lg:h-[134px] rounded-[12px]"
              >
                <div className="mr-4">
                  <p className="text-center text-m">{I18n.get("hl")}</p>
                  <div className="row-left flex cursor-pointer mt-2">
                    <p className="subtitle whitespace-nowrap text-left">
                      {I18n.get("conRead")}
                    </p>
                    <img
                      alt="home list"
                      src="/images/include/right.png"
                      className="h-[10px] w-auto object-contain ml-1 mt-2"
                    />
                  </div>
                </div>
                <div className="flex-auto flex items-end">
                  <img
                    alt="home list"
                    src="/images/products/hl.png"
                    className="h-[94px] lg:h-[94px] w-auto object-cover object-left"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);
