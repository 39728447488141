import Head from "next/head";
import Meta from "./Meta";
import Header from "./Header";
import Footer from "./Footer";
import Script from "next/script";
import { useState, useContext } from "react";
import CookieDialog from "./CookieDialog";
import { I18nContext } from "../I18N";
export default function Layout({
  children,
  header = true,
  headerFullwidth = false,
  meta = false,
  menuOffset = false,
}) {
  const { langCode } = useContext(I18nContext);
  return (
    <div>
      {!meta ? <Meta /> : meta}
      <div
        className="w-full min-h-screen flex flex-col"
        style={{ minHeight: "100vh" }}
      >
        {header && headerFullwidth && (
          <div className="container-wrapper">
            <div className="font-[CentraNo2] h-[70px] lg:h-[88px] flex justify-between items-center px-[10px] md:px-[17px] py-[20px] space-x-[20px] sm:space-x-[48px] lg:space-x-[64px] lg:px-0 lg:mx-auto">
              <Header menuOffset={menuOffset} />
            </div>
          </div>
        )}

        {header && !headerFullwidth && (
          <div className="font-[CentraNo2] h-[70px] lg:h-[88px] flex justify-between items-center px-[10px] md:px-[17px] py-[20px] space-x-[20px] sm:space-x-[48px] lg:space-x-[64px] lg:px-0 lg:w-[990] xl:w-[1197] lg:mx-auto">
            <Header menuOffset={menuOffset} />
          </div>
        )}
        <main className={`flex-1 w-full flex flex-col ${langCode}-font`}>
          {children}
        </main>
        <Footer />
      </div>
      <CookieDialog />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            document.addEventListener('DOMContentLoaded', function() {
              var noscript2 = document.createElement('noscript');
              var iframe = document.createElement('iframe');
              iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-K9MNLKS";
              iframe.display = "display:none;visibility:hidden";
              noscript2.appendChild(iframe)
              document.getElementsByTagName('body')[0].appendChild(noscript2);
            });
            `,
        }}
      />
    </div>
  );
}
